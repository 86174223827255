import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";
import List from "./List";

import {
  FcFilingCabinet,
  FcParallelTasks,
  FcStackOfPhotos,
  FcBusinessman,
  FcAddImage,
  FcAddressBook,
} from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";
import {
  MdAdminPanelSettings,
  MdBadge,
  MdNotificationAdd,
} from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";

const Sidebar = ({ isOpen, onOpen, onClose }) => {
  return (
    <Box
      borderRight={{
        base: "none",
        md: "1px solid #eee",
      }}
      h="100%"
      sx={{
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <List
        data={[
          { name: "DashBoard", icon: <FcFilingCabinet />, link: "/" },
          { name: "Subcription", icon: <MdBadge />, link: "/subscribtion" },
          { name: "Movies", link: "/movies", icon: <FcAddImage /> },
          { name: "Series", link: "/series", icon: <GrMultiple /> },
          {
            name: "Categories",
            link: "/categories",
            icon: <FcParallelTasks />,
          },
          {
            name: "Banner",
            link: "/banner",
            icon: <FcStackOfPhotos />,
          },
          {
            name: "Users",
            link: "/users",
            icon: <FcBusinessman />,
          },
          {
            name: "Notifications",
            link: "/notification",
            icon: <MdNotificationAdd />,
          },
          {
            name: "Admin",
            link: "/admins",
            icon: <MdAdminPanelSettings />,
          },
          {
            name: "Genres",
            link: "/genres",
            icon: <FcAddressBook />,
          },
          {
            name: "Rent",
            link: "/rent",
            icon: <GiReceiveMoney />,
          },
        ]}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"} placement={"left"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Tangent Ott`}</DrawerHeader>
          <DrawerBody>
            <List
              data={[
                { name: "DashBoard", icon: <FcFilingCabinet />, link: "/" },
                {
                  name: "Subcription",
                  icon: <MdBadge />,
                  link: "/subscribtion",
                },
                { name: "Movies", link: "/movies", icon: <FcAddImage /> },
                { name: "Series", link: "/series", icon: <GrMultiple /> },
                {
                  name: "Categories",
                  link: "/categories",
                  icon: <FcParallelTasks />,
                },
                {
                  name: "Banner",
                  link: "/banner",
                  icon: <FcStackOfPhotos />,
                },
                {
                  name: "Users",
                  link: "/users",
                  icon: <FcBusinessman />,
                },
                {
                  name: "Notifications",
                  link: "/notification",
                  icon: <MdNotificationAdd />,
                },
                {
                  name: "Admin",
                  link: "/admins",
                  icon: <MdAdminPanelSettings />,
                },
                {
                  name: "Genres",
                  link: "/genres",
                  icon: <FcAddressBook />,
                },
                {
                  name: "Rent",
                  link: "/rent",
                  icon: <GiReceiveMoney />,
                },
              ]}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
