import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import { getAllGeneres } from "../../utils/api";
import CheckboxInput from "../common/TextInputs/Checkbox";
import CreateSelect from "../common/TextInputs/CreateSelect";
const Modal = ({ save, title, episeries, series, update, color }) => {
  const toast = useToast();
  const [newCast, setNewCast] = React.useState({
    name: "",
    image: "",
    role: "",
  });
  const [newGenre, setNewGenre] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [genre, setGenre] = React.useState([]);
  const [allGenre, setAllGenre] = React.useState([]);
  const [newPodcast, setNewPodcast] = React.useState({
    accessTime: 1,
    price: 0,
  });
  const [cast, setCast] = React.useState([]);
  const podcastDataChage = (e, name) => {
    const { value } = e.target;
    setNewPodcast((prev) => ({ ...prev, [name]: value }));
  };
  const durationCovertion = (duration) => {
    let time = duration;
    let sp = time?.split(" ");
    let hr = 0;
    let min = 0;
    let sec = 0;

    for (let i = 0; i < sp?.length; i++) {
      if (sp[i]?.includes("hr")) {
        const textArray = sp[i]?.split("hr");
        hr = Number(textArray?.shift());
        if (isNaN(hr)) {
          hr = 0;
        }
      } else if (sp[i]?.includes("min")) {
        const textArray = sp[i]?.split("min");
        min = Number(textArray?.shift());
      } else {
        const textArray = sp[i]?.split("sec");
        sec = Number(textArray?.shift());
      }
    }

    return {
      hr,
      min,
      sec,
    };
  };
  const getAllGeneresHandler = async () => {
    const [success, data] = await getAllGeneres();
    if (success) {
      setAllGenre(data);
    }
  };

  useEffect(() => {
    if (update) {
      setNewPodcast({
        name: update?.name,
        url: update?.url,
        urlType: update?.urlType,
        description: update?.description,
        verticalBanner: update?.verticalBanner,
        horizontalBanner: update?.horizontalBanner,
        duration: durationCovertion(update?.duration),
        language: update?.language,
        rating: update?.rating,
        ageLimit: update?.ageLimit,
        trailer: update?.trailer,
        price: update?.price,
        accessTime: update?.accessTime,
        trailerType: update?.trailerType,
        isPaid: update?.isPaid,
        isPremium: update?.isPremium,
        isFree: update?.isFree,
      });
      setGenre(update?.genres);
      setCast(update?.cast);
    }
  }, [update]);

  const saveHandler = async () => {
    if (
      newPodcast.name &&
      newPodcast.description &&
      newPodcast.verticalBanner &&
      newPodcast.horizontalBanner &&
      (episeries || newPodcast.duration) &&
      (episeries || newPodcast.language) &&
      (episeries || newPodcast.rating) &&
      (episeries || newPodcast.ageLimit) &&
      (episeries || genre.length > 0)
    ) {
      let duration = "";
      if (+newPodcast?.duration?.hr !== 0) {
        duration = duration + newPodcast?.duration?.hr + "hr ";
      }
      if (+newPodcast?.duration?.min !== 0) {
        duration = duration + newPodcast?.duration?.min + "min ";
      }
      if (+newPodcast?.duration?.sec !== 0) {
        duration = duration + newPodcast?.duration?.sec + "sec";
      }
      const newPodcastData = {
        ...newPodcast,
        duration: duration,

        genres: genre,
        cast: cast,
        accessTime: newPodcast?.isPaid ? newPodcast.accessTime : 1,
        price: newPodcast?.isPaid ? newPodcast.price : 0,
      };

      if (!update) {
        setNewPodcast((prev) => ({
          ...prev,
          name: "",
          description: "",
          verticalBanner: "",
          horizontalBanner: "",
          url: "",
          urlType: "",
          duration: {
            hr: 0,
            min: 0,
            sec: 0,
          },
        }));
      }
      await save(newPodcastData, update?._id);
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields correctly",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      sx={{
        gap: 3,
        width: "100%",
      }}
    >
      <Button
        onClick={() => {
          getAllGeneresHandler();
          onOpen();
        }}
        sx={{
          width: "100%",
        }}
        leftIcon={!color && <BsPlus />}
        colorScheme={color ? color : "blue"}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={saveHandler}
      >
        <CreateInput
          name="Name"
          onChange={(e) => podcastDataChage(e, "name")}
          value={newPodcast?.name ? newPodcast.name : ""}
        />
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Description"
            onChange={(e) => {
              podcastDataChage(e, "description");
            }}
            value={newPodcast?.description ? newPodcast.description : ""}
          />
        </FormControl>
        {!series && (
          <>
            {" "}
            <Text fontSize={"md"} fontWeight={"semibold"}>
              Url-Type
            </Text>
            <Select
              placeholder="Select Url-Type"
              value={newPodcast?.urlType ? newPodcast.urlType : ""}
              onChange={(e) => {
                setNewPodcast((prev) => ({
                  ...prev,
                  urlType: e.target.value,
                }));
              }}
            >
              <option value="Youtube">Youtube</option>
              <option value="Video">Video</option>
            </Select>
          </>
        )}
        {!series && (
          <>
            <CreateInput
              name="Url"
              onChange={(e) => {
                podcastDataChage(e, "url");
              }}
              value={newPodcast?.url ? newPodcast.url : ""}
            />
          </>
        )}
        <CreateInput
          name={"Horizontal-Banner"}
          onChange={(e) => {
            podcastDataChage(e, "horizontalBanner");
          }}
          value={
            newPodcast?.horizontalBanner ? newPodcast.horizontalBanner : ""
          }
        />
        <CreateInput
          name={"Vertical-Banner"}
          onChange={(e) => {
            podcastDataChage(e, "verticalBanner");
          }}
          value={newPodcast?.verticalBanner ? newPodcast.verticalBanner : ""}
        />
        {!episeries && (
          <>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isPaid" mb="0">
                Is Paid
              </FormLabel>
              <Switch
                id="isPaid"
                isChecked={newPodcast?.isPaid ? newPodcast.isPaid : false}
                onChange={(e) => {
                  setNewPodcast((prev) => ({
                    ...prev,
                    isPaid: e.target.checked,
                    isFree: e.target.checked
                      ? !e.target.checked
                      : newPodcast.isFree,
                  }));
                }}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isFree" mb="0">
                Is Free
              </FormLabel>
              <Switch
                id="isFree"
                isChecked={newPodcast?.isFree ? newPodcast.isFree : false}
                onChange={(e) => {
                  setNewPodcast((prev) => ({
                    ...prev,
                    isFree: e.target.checked,
                    isPaid: e.target.checked
                      ? !e.target.checked
                      : newPodcast.isPaid,
                  }));
                }}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isPremium" mb="0">
                Is Premium
              </FormLabel>
              <Switch
                id="isPremium "
                isChecked={newPodcast?.isPremium ? newPodcast.isPremium : false}
                onChange={(e) => {
                  setNewPodcast((prev) => ({
                    ...prev,
                    isPremium: e.target.checked,
                  }));
                }}
              />
            </FormControl>
            <CreateSelect
              name="Trailer-Type"
              value={newPodcast?.trailerType}
              onChange={(e) => {
                setNewPodcast((prev) => ({
                  ...prev,
                  trailerType: e.target.value,
                }));
              }}
            >
              <option value="Youtube">Youtube</option>
              <option value="Video">Video</option>
            </CreateSelect>
            <CreateInput
              name={"Trailer"}
              onChange={(e) => {
                podcastDataChage(e, "trailer");
              }}
              value={newPodcast?.trailer ? newPodcast.trailer : ""}
            />
            <Box
              sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}
            >
              <Text fontSize={"md"} fontWeight={"semibold"}>
                Duration
              </Text>
              <Box sx={{ display: "flex", gap: "0.7rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Select
                    value={newPodcast?.duration?.hr}
                    onChange={(e) => {
                      setNewPodcast((prev) => ({
                        ...prev,
                        duration: {
                          ...prev.duration,
                          hr: e.target.value,
                        },
                      }));
                    }}
                  >
                    {Array.from(Array(25).keys()).map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                  <Text fontSize={"md"}>hr</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Select
                    value={newPodcast?.duration?.min}
                    onChange={(e) => {
                      setNewPodcast((prev) => ({
                        ...prev,
                        duration: {
                          ...prev.duration,
                          min: e.target.value,
                        },
                      }));
                    }}
                  >
                    {Array.from(Array(61).keys()).map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                  <Text fontSize={"md"}>min</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Select
                    value={newPodcast?.duration?.sec}
                    onChange={(e) => {
                      setNewPodcast((prev) => ({
                        ...prev,
                        duration: {
                          ...prev.duration,
                          sec: e.target.value,
                        },
                      }));
                    }}
                  >
                    {Array.from(Array(61).keys()).map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>

                  <Text fontSize={"md"}>sec</Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem 0rem",
                gap: ".5rem",
              }}
            >
              <FormLabel size={"sm"}>Genre</FormLabel>
              {genre?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    backgroundColor: "#eee",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {genre.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.name
                        ? item.name
                        : allGenre?.find((items) => items._id === item)?.name}
                      <Text
                        sx={{
                          color: "red.500",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newGenre = genre.filter(
                            (item, i) => i !== index
                          );
                          setGenre(newGenre);
                        }}
                      >
                        Remove
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
              {/* <InputGroup size="md">
            <Input
              pr="4.5rem"
              placeholder="Add"
              value={newGenre}
              onChange={(e) => {
                setNewGenre(e.target.value);
              }}
            />
            <InputRightElement width="7rem">
              <Button
                size="sm"
                onClick={() => {
                  setGenre((prev) => [...prev, newGenre]);
                  setNewGenre("");
                }}
              >
                Create Genre
              </Button>
            </InputRightElement>
          </InputGroup> */}

              {allGenre?.length > 0 &&
                allGenre.map((item, index) => (
                  <CheckboxInput
                    name={item.name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setGenre((prev) => [...prev, item._id]);
                      } else {
                        const newGenre = genre.filter(
                          (items) => items !== item._id
                        );
                        setGenre(newGenre);
                      }
                    }}
                    value={item._id}
                    isChecked={genre?.includes(item._id)}
                  />
                ))}
            </Box>
            <CreateSelect
              name="Rating"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return { ...prev, rating: e.target.value };
                });
              }}
              value={newPodcast?.rating ? newPodcast.rating : ""}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </CreateSelect>
            <CreateSelect
              name="Language"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    language: e.target.value,
                  };
                });
              }}
              value={newPodcast?.language ? newPodcast.language : ""}
            >
              <option value="English">English</option>
              <option value="Marathi">Marathi</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Gujarati">Gujarati</option>
              <option value={"Bengali"}>Bengali</option>
              <option value={"Bhojpuri"}>Bhojpuri</option>
              <option value={"Tamil"}>Tamil</option>
              <option value={"Telugu"}>Telugu</option>
              <option value={"Kannada"}>Kannada</option>
              <option value={"Malayalam"}>Malayalam</option>
              <option value={"Urdu"}>Urdu</option>
              <option value={"Tulu"}>Tulu</option>
              <option value={"Assamese"}>Assamese</option>
            </CreateSelect>
            <CreateInput
              name="Age-Limit"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    ageLimit: e.target.value,
                  };
                });
              }}
              value={newPodcast?.ageLimit ? newPodcast.ageLimit : ""}
            />
            {newPodcast.isPaid && (
              <>
                <CreateInput
                  name="Access Time (in Days)"
                  onChange={(e) => {
                    setNewPodcast((prev) => {
                      return {
                        ...prev,
                        accessTime: e.target.value,
                      };
                    });
                  }}
                  type="number"
                  value={newPodcast?.accessTime ? newPodcast.accessTime : ""}
                />
                <CreateInput
                  name="Price"
                  onChange={(e) => {
                    setNewPodcast((prev) => {
                      return {
                        ...prev,
                        price: e.target.value,
                      };
                    });
                  }}
                  type="number"
                  value={newPodcast?.price}
                />
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem 0rem",
                gap: ".5rem",
              }}
            >
              <FormLabel size={"sm"}>Cast</FormLabel>
              {cast?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    backgroundColor: "#eee",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {cast.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <Text>{item.name}</Text>
                      <Text
                        sx={{ color: "red.500", cursor: "pointer" }}
                        onClick={() => {
                          const newCast = cast.filter((item, i) => i !== index);
                          setCast(newCast);
                        }}
                      >
                        Remove
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  border: "1px solid #eee",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Input
                  placeholder="Name"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, name: e.target.value }));
                  }}
                  value={newCast?.name}
                />
                <Input
                  placeholder="Image-Url"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, image: e.target.value }));
                  }}
                  value={newCast?.image}
                />
                <Input
                  placeholder="Role"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, role: e.target.value }));
                  }}
                  value={newCast?.role}
                />
                <Button
                  size="sm"
                  onClick={() => {
                    setCast((prev) => [...prev, newCast]);
                    setNewCast({
                      name: "",
                      image: "",
                      role: "",
                    });
                  }}
                >
                  Create Cast
                </Button>
              </Box>
            </Box>
            <CreateInput
              name="Extra-Cast"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    extraCast: e.target.value,
                  };
                });
              }}
              value={newPodcast?.extraCast ? newPodcast.extraCast : ""}
            />
          </>
        )}
      </CreateModal>
    </Box>
  );
};

export default Modal;
